import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Header from './content/Header';
import Sidebar from './content/Sidebar';
import Home from './content/Home';
import About from './content/About';
import Summary from './content/Summary';
import Services from './content/Services';
import Contact from './content/Contact';
import Footer from './content/Footer';
import NotFound from './NotFound';
import Policy from './content/Policy';

import { english, spanish } from '../data/languages';

const Dashboard = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const userLang = navigator.language || navigator.userLanguage;
    const browserLanguage = userLang.split("-")[0];

    const localStorageLanguage = localStorage.getItem("lang");

    if (!localStorageLanguage) localStorage.setItem("lang", browserLanguage);
    const lang = localStorageLanguage;
    

    const [showPage, setShowPage] = useState("home");
    const [hidePage, setHidePage] = useState(["not-found", "policy"]);
    const [hideMenu, setHideMenu] = useState(true);

    const path = {
        '/':'home',
    };

    const locationPath = path[location.pathname];
    

    function reloadPage(page) {
        if (showPage === "not-found") {
            if(page && page === "policy") {
                setHidePage(["not-found"]);
                navigate('/');
            } else {
                setHidePage(["not-found", "policy"]);
                navigate('/');
            }
        }
    };

    function changeLanguage(){
        if(hidePage.includes("not-found")) setHidePage(["not-found"]);
        if(hidePage.includes("policy")) setHidePage(prevState=>[...prevState, "policy"]);
        lang === 'es' ? localStorage.setItem("lang", 'en') : localStorage.setItem("lang", 'es')
    }
    
    return (
        <div id="global">
            <div id="wrapper">
                <Sidebar
                    showPage={showPage}
                    setShowPage={setShowPage}
                    hidePage={hidePage}
                    language={lang === 'es' ? spanish : english}
                    hideMenu={hideMenu}
                    setHideMenu={setHideMenu}
                    reloadPage={reloadPage}
                    changeLanguage={changeLanguage}
                />
                {!hideMenu ? <div className="hide-dashboard"></div> : null}
                <Header 
                    showPage={showPage}
                    setShowPage={setShowPage}
                    hidePage={hidePage}
                    setHidePage={setHidePage}
                    language={lang === 'es' ? spanish.menu : english.menu}
                    reloadPage={reloadPage}
                />
                <div id="dashboard">
                    <div id="content">
                        {
                            (!locationPath) 
                            ? <NotFound
                                setShowPage={setShowPage}
                                hidePage={hidePage}
                                setHidePage={setHidePage}
                                reloadPage={reloadPage}
                                language={lang === 'es' ? spanish.not_found : english.not_found}
                            /> 
                            : showPage === "about"
                            ? <About setShowPage={setShowPage} language={lang === 'es' ? spanish.about : english.about}/> 
                            : showPage === "summary"
                            ? <Summary language={lang === 'es' ? spanish.summary : english.summary}/>
                            : showPage === "services"
                            ? <Services language={lang === 'es' ? spanish.services : english.services}/>
                            : showPage === "contact"
                            ? <Contact language={lang === 'es' ? spanish.contact : english.contact}/>
                            : showPage === "policy"
                            ? <Policy language={lang === 'es' ? spanish.policy : english.policy} hidePage={hidePage}/>
                            : <Home hidePage={hidePage} language={lang === 'es' ? spanish : english}/>
                        }
                    </div>
                </div>
            <Footer 
                hidePage={hidePage} 
                setShowPage={setShowPage} 
                language={lang === 'es' ? spanish.footer : english.footer}
                reloadPage={reloadPage}
            />
            </div>
        </div>
    );
};

export default Dashboard;