import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';

const Summary = ({ language }) => {
    return (
        <div id="summary" className="wrapper">
            <h2>{'<'}<span className="cian"><em>{language.summary}</em></span>{' />'}</h2>
            <div className="white wrapper-description">
                <div className="padding-bottom">
                    <div>
                        <span className="red">export</span>
                        <span className="cian"> const </span>
                        <span>{language.work.title}</span>
                        <span className="red"> = </span>
                        <span className="yellow">{' ['}</span>
                    </div>
                    <div className="padding-left">
                        <div>
                            <span>{language.work.modelit.company}:</span>
                            <span className="purple">{' {'}</span> <br />
                            <div className="padding-left">
                                <p>{language.words.role}: <span className="yellow-text">'{language.work.modelit.role}'</span>,</p>
                                <p>{language.words.activity}: <span className="yellow-text">'{language.work.modelit.year}'</span>,</p>
                                <p>{language.words.tasks}: <span className="yellow">{' ['}</span></p>
                                <div className="padding-left">
                                    <p>{language.words.task}1: <span className="yellow-text">'{language.work.modelit.first}'</span>,</p>
                                    <p>{language.words.task}2: <span className="yellow-text">'{language.work.modelit.second}'</span>,</p>
                                    <p>{language.words.task}3: <span className="yellow-text">'{language.work.modelit.third}'</span>,</p>
                                    <p>{language.words.task}4: <span className="yellow-text">'{language.work.modelit.fourth}'</span>,</p>
                                    <p>{language.words.task}5: <span className="yellow-text">'{language.work.modelit.fifth}'</span>,</p>
                                </div>
                                <span className="yellow">{']'}</span>,
                                <p>{language.words.technologies}: <span className="yellow-text">'{language.work.modelit.technologies}'</span>,</p>
                            </div>
                            <span className="purple">{'}'}</span>,
                        </div>
                        <div>
                            <span>{language.work.whnz.company}:</span>
                            <span className="purple">{' {'}</span> <br />
                            <div className="padding-left">
                                <p>{language.words.title}: <span className="yellow-text">'{language.work.whnz.title}'</span>,</p>
                                <p>{language.words.activity}: <span className="yellow-text">'{language.work.whnz.year}'</span>,</p>
                                <p>{language.words.tasks}: <span className="yellow-text">'{language.work.whnz.first}'</span>,</p>
                            </div>
                            <span className="purple">{'}'}</span>,
                        </div>
                        <div>
                            <span>{language.work.universal.company}:</span>
                            <span className="purple">{' {'}</span> <br />
                            <div className="padding-left">
                                
                                <p>{language.words.role}: <span className="yellow-text">'{language.work.universal.role}'</span>,</p>
                                <p>{language.words.activity}: <span className="yellow-text">'{language.work.universal.year}'</span>,</p>
                                <p>{language.words.tasks}: <span className="yellow">{' ['}</span></p>
                                <div className="padding-left">
                                    <p>{language.words.task}1: <span className="yellow-text">'{language.work.universal.first}'</span>,</p>
                                    <p>{language.words.task}2: <span className="yellow-text">'{language.work.universal.second}'</span>,</p>
                                    <p>{language.words.task}3: <span className="yellow-text">'{language.work.universal.third}'</span>,</p>
                                    <p>{language.words.task}4: <span className="yellow-text">'{language.work.universal.fourth}'</span>,</p>
                                    <p>{language.words.task}5: <span className="yellow-text">'{language.work.universal.fifth}'</span>,</p>
                                </div>
                                <span className="yellow">{']'}</span>,
                            </div>
                            <span className="purple">{'}'}</span>,
                        </div>
                    </div>
                    <span className="yellow">{']'}</span>;
                </div>
                <div className="padding-bottom">
                    <div>
                        <span className="red">export</span>
                        <span className="cian"> const </span>
                        <span>{language.education.title}</span>
                        <span className="red"> = </span>
                        <span className="yellow">{' ['}</span>
                    </div>
                    <div className="padding-left">
                        <span className="purple">{'{'}</span> <br />
                        <div className="padding-left">
                            <p>{language.words.title}: <span className="yellow-text">'{language.education.first.title}'</span>,</p>
                            <p>{language.words.school}: <span className="yellow-text">'{language.education.first.institute}'</span>,</p>
                            <p>{language.words.year}: <span className="yellow-text">'{language.education.first.year}'</span>,</p>
                            <p>{language.words.country}: <span className="yellow-text">'{language.education.first.country}'</span>,</p>
                        </div>
                        <span className="purple">{'}'}</span>, <br />
                        <span className="purple">{'{'}</span> <br />
                        <div className="padding-left">
                            <p>{language.words.title}: <span className="yellow-text">'{language.education.second.title}'</span>,</p>
                            <p>{language.words.school}: <span className="yellow-text">'{language.education.second.institute}'</span>,</p>
                            <p>{language.words.year}: <span className="yellow-text">'{language.education.second.year}'</span>,</p>
                            <p>{language.words.country}: <span className="yellow-text">'{language.education.second.country}'</span>,</p>
                        </div>
                        <span className="purple">{'}'}</span>, <br />
                        <span className="purple">{'{'}</span> <br />
                        <div className="padding-left">
                            <p>{language.words.title}: <span className="yellow-text">'{language.education.third.title}'</span>,</p>
                            <p>{language.words.school}: <span className="yellow-text">'{language.education.third.institute}'</span>,</p>
                            <p>{language.words.year}: <span className="yellow-text">'{language.education.third.year}'</span>,</p>
                            <p>{language.words.country}: <span className="yellow-text">'{language.education.third.country}'</span>,</p>
                        </div>
                        <span className="purple">{'}'}</span>, <br />
                    </div>
                    <span className="yellow">{']'}</span>;
                </div>
                <div>
                    <div>
                        <span className="red">export</span>
                        <span className="cian"> const </span>
                        <span>{language.certifications.title}</span>
                        <span className="red"> = </span>
                        <span className="yellow">{' ['}</span>
                    </div>
                    <div className="padding-left">
                        <span className="purple">{'{'}</span> <br />
                        <div className="padding-left">
                            <p>{language.words.title}: <span className="yellow-text">'{language.certifications.first.title}'</span>,</p>
                            <p>{language.words.school}: <span className="yellow-text">'{language.certifications.first.institute}'</span>,</p>
                            <p>{language.words.year}: <span className="yellow-text">'{language.certifications.first.year}'</span>,</p>
                            <p>{language.words.country}: <span className="yellow-text">'{language.certifications.first.country}'</span>,</p>
                        </div>
                        <span className="purple">{'}'}</span>, <br />
                        <span className="purple">{'{'}</span> <br />
                        <div className="padding-left">
                            <p>{language.words.title}: <span className="yellow-text">'{language.certifications.second.title}'</span>,</p>
                            <p>{language.words.school}: <span className="yellow-text">'{language.certifications.second.institute}'</span>,</p>
                            <p>{language.words.year}: <span className="yellow-text">'{language.certifications.second.year}'</span>,</p>
                            <p>{language.words.country}: <span className="yellow-text">'{language.certifications.second.country}'</span>,</p>
                        </div>
                        <span className="purple">{'}'}</span>, <br />
                        <span className="purple">{'{'}</span> <br />
                        <div className="padding-left">
                            <p>{language.words.title}: <span className="yellow-text">'{language.certifications.third.title}'</span>,</p>
                            <p>{language.words.school}: <span className="yellow-text">'{language.certifications.third.institute}'</span>,</p>
                            <p>{language.words.year}: <span className="yellow-text">'{language.certifications.third.year}'</span>,</p>
                            <p>{language.words.country}: <span className="yellow-text">'{language.certifications.third.country}'</span>,</p>
                        </div>
                        <span className="purple">{'}'}</span>, <br />
                        <span className="purple">{'{'}</span> <br />
                        <div className="padding-left">
                            <p>{language.words.title}: <span className="yellow-text">'{language.certifications.fourth.title}'</span>,</p>
                            <p>{language.words.school}: <span className="yellow-text">'{language.certifications.fourth.institute}'</span>,</p>
                            <p>{language.words.year}: <span className="yellow-text">'{language.certifications.fourth.year}'</span>,</p>
                            <p>{language.words.country}: <span className="yellow-text">'{language.certifications.fourth.country}'</span>,</p>
                        </div>
                        <span className="purple">{'}'}</span>, <br />
                        <span className="purple">{'{'}</span> <br />
                        <div className="padding-left">
                            <p>{language.words.title}: <span className="yellow-text">'{language.certifications.fifth.title}'</span>,</p>
                            <p>{language.words.school}: <span className="yellow-text">'{language.certifications.fifth.institute}'</span>,</p>
                            <p>{language.words.year}: <span className="yellow-text">'{language.certifications.fifth.year}'</span>,</p>
                            <p>{language.words.country}: <span className="yellow-text">'{language.certifications.fifth.country}'</span>,</p>
                        </div>
                        <span className="purple">{'}'}</span>,
                    </div>
                    <span className="yellow">{']'}</span>;
                </div>
            </div>
        </div>
    );
};

export default Summary;