import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faCopy, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";

import html from "../../icons/html.svg";
import js from "../../icons/js.svg";
import json from "../../icons/json.svg";
import php from "../../icons/php.svg";

library.add(faCopy, faGithub, faInstagram, faEnvelope, faLinkedin, faLanguage);

const Sidebar = ({ showPage, setShowPage, hidePage, language, hideMenu, setHideMenu, reloadPage, changeLanguage }) => {

    const lang = language.menu;
    function unsetHidePage(page) {
        if(hidePage.includes(page)) hidePage.splice(hidePage.indexOf(page), 1);
    }
    
    return (
        <div id="sidebar">
            <div id="icons">
                <span className="faIcon first-icon">
                    <FontAwesomeIcon size="2x" icon="far fa-copy" onClick={() => setHideMenu(prevCheck => !prevCheck)}/>
                </span>
                <span 
                    className="faIcon language"
                    onClick={
                        (e) => {
                            e.stopPropagation();
                            changeLanguage(showPage);
                        }
                    }
                >
                    <FontAwesomeIcon size="xl" icon="fas fa-language" />
                    <span>{language.language === 'es' ? 'ES' : 'EN'}</span>
                </span>
                <a href="https://github.com/agustoth56" target="_blank" alt="" className="faIcon" rel="noreferrer">
                    <FontAwesomeIcon size="2x" icon="fab fa-github" />
                </a>
                <a href="https://www.linkedin.com/in/agustin-toth/" target="_blank" alt="" className="faIcon" rel="noreferrer">
                    <FontAwesomeIcon size="2x" icon="fab fa-linkedin" />
                </a>
                <a href="mailto:agustoth56@gmail.com" alt="" className="faIcon">
                    <FontAwesomeIcon size="2x" icon="far fa-envelope" />
                </a>
                <a href="https://www.instagram.com/agustoth56/" target="_blank" alt="" className="faIcon" rel="noreferrer">
                    <FontAwesomeIcon size="2x" icon="fab fa-instagram" />
                </a>
                
            </div>
            <div id="explorer" className={hideMenu ? 'hide-menu' : 'show-menu'} onClick={() => setHideMenu(prevCheck => !prevCheck)}>
                <span className="explorer">Agustin Toth</span>
                <div id="files">
                    <ul>
                        <li className={showPage === 'home' ? 'active' : ''}>
                            <div 
                                className="menu-item home-item" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage('home');
                                    setHideMenu(prevCheck => !prevCheck);
                                    reloadPage();
                                }}
                            >
                                <img className="menu-icon" src={html} alt=""/>
                                <span>{lang.home}.html</span>
                            </div>
                        </li>
                        <li className={showPage === 'about' ? 'active' : ''}>
                            <div 
                                className="menu-item" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage('about');
                                    unsetHidePage('about');
                                    setHideMenu(prevCheck => !prevCheck);
                                    reloadPage();
                                }}
                            >
                                <img className="menu-icon" src={js} alt="" />
                                <span>{lang.about}.js</span>
                            </div>
                        </li>
                        <li className={showPage === 'summary' ? 'active' : ''}>
                            <div 
                                className="menu-item" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage('summary');
                                    unsetHidePage('summary');
                                    setHideMenu(prevCheck => !prevCheck);
                                    reloadPage();
                                }}
                            >
                                <img className="menu-icon" src={json} alt="" />
                                <span>{lang.summary}.json</span>
                            </div>
                        </li>
                        <li className={showPage === 'services' ? 'active' : ''}>
                            <div 
                                className="menu-item" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage('services');
                                    unsetHidePage('services');
                                    setHideMenu(prevCheck => !prevCheck);
                                    reloadPage();
                                }}
                            >
                                <img className="menu-icon" src={html} alt="" />
                                <span>{lang.services}.html</span>
                            </div>
                        </li>
                        <li className={showPage === 'contact' ? 'active' : ''}>
                            <div 
                                className="menu-item" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage('contact');
                                    unsetHidePage('contact');
                                    setHideMenu(prevCheck => !prevCheck);
                                    reloadPage();
                                }}
                            >
                                <img className="menu-icon" src={php} alt="" />
                                <span>{lang.contact}.php</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;