import 'bootstrap/dist/css/bootstrap.css';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

const Contact = ({ language }) => {

    const form = useRef();
    const [formSent, setFormSent] = useState({success: false, error: false});

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE,
            process.env.REACT_APP_EMAILJS_TEMPLATE,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then((result) => {
            setFormSent({success: true, error: false});
        }, (error) => {
            setFormSent({success: false, error: true});
        });
    };

    return (
        <div id="contact" className="wrapper">
            <h2>{'<'}<span className="cian">{language.contact}</span>{' />'}</h2>
            <div className="wrapper-description form">
                {
                    formSent.success &&
                    !formSent.error && 
                    <div className="form-success">
                        {language.form.success}
                    </div>}
                {
                    !formSent.success &&
                    formSent.error && 
                    <div className="form-error">
                        {language.form.error}
                    </div>
                }
                <form  ref={form} onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder={language.form.name}
                            {...register(
                                'name',
                                {
                                    required: true,
                                    pattern: /^[A-Za-z_ ]+$/i,
                                    maxLength: 30
                                }
                            )}
                        />
                        {errors.name?.type === 'required' && <p className="error">Name is required.</p>}
                        {errors.name?.type === 'pattern' && <p className="error">Name cannot contains numbers.</p>}
                        {errors.name?.type === 'maxLength' && <p className="error">The length of name must 30 characters or fewer.</p>}
                    </div>
                    <div className="">
                        <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder={language.form.email}
                            {...register(
                                'email',
                                { 
                                    required: true,
                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    maxLength: 70
                                }
                            )}
                        />
                        {errors.email?.type === 'required' && <p className="error">Email is required.</p>}
                        {errors.email?.type === 'pattern' && <p className="error">Enter a valid email please.</p>}
                        {errors.email?.type === 'maxLength' && <p className="error">The length of email must 70 characters or fewer.</p>}
                    </div>
                    <div className="">
                        <input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder={language.form.subject}
                            {...register(
                                'subject',
                                {
                                    required: true,
                                    maxLength: 30
                                }
                            )}
                        />
                        {errors.subject?.type === 'required' && <p className="error">Subject is required.</p>}
                        {errors.subject?.type === 'maxLength' && <p className="error">The length of subject must 30 characters or fewer.</p>}
                    </div>
                    <div className="">
                        <textarea 
                            name="message"
                            id="message"
                            rows="5"
                            placeholder={language.form.message}
                            {...register(
                                'message',
                                {
                                    required: true,
                                    maxLength: 255
                                }
                            )}
                        ></textarea>
                        {errors.message?.type === 'required' && <p className="error">Message is required.</p>}
                        {errors.message?.type === 'maxLength' && <p className="error">The length of message must 255 characters or fewer.</p>}
                    </div>
                    <div className="">
                        <button name="submit" type="submit" title="Send Message">{language.form.button}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Contact;