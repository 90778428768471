export const english = {
    language: "en",
    menu: {
        home: 'home',
        about: 'about-me',
        summary: 'summary',
        services: 'services',
        contact: 'contact',
        policy: 'privacy-policy',
        not_found: 'not-found',
    },
    home: {
        first: 'Hi there! My name is',
        second: 'Full-stack Developer and Web Designer',
    },
    about: {
        about: 'About Me',
        firstLine: `I'm 25 and I was born and currently live in Uruguay but I do also enjoy travelling around the world!`,
        secondLine: `I've been a huge fan about technology and software development since I was a kid.`,
        thirdLine: 'I started my studies in IT when I was 15 but a big part of my knowledge was being self-taught.',
        fourthLine: `Here are some technologies that I've been working and studying recently:`,
        design: 'design',
    },
    summary: {
        summary: 'Summary',
        education: {
            title: 'Education',
            first: {
                title: 'Master in Full-stack Development',
                institute: 'Universitat Politècnica de Catalunya',
                year: '2021-2022',
                country: 'Spain',
            },
            second: {
                title: 'Software Analyst',
                institute: 'ORT University',
                year: '2019',
                country: 'Uruguay',
            },
            third: {
                title: 'IT Technician',
                institute: 'UTU ISBO',
                year: '2014-2017',
                country: 'Uruguay',
            },
        },
        certifications: {
            title: 'Certifications',
            first: {
                title: 'JavaScript Developer',
                institute: 'Salesforce',
                year: '2023',
                country: '-',
            },
            second: {
                title: 'Multimedia Design',
                institute: 'University of Salamanca',
                year: '2019',
                country: 'Spain',
            },
            third: {
                title: 'PHP & MySQL Developer',
                institute: 'Taller de Informática',
                year: '2018',
                country: 'Uruguay',
            },
            fourth: {
                title: 'Graphic Design',
                institute: 'BIOS',
                year: '2017',
                country: 'Uruguay',
            },
            fifth: {
                title: 'Leadership Training',
                institute: 'Colegio y Liceo Pedro Poveda',
                year: '2013-2014',
                country: 'Uruguay',
            },
        },
        work: {
            title: 'Work',
            modelit: {
                company: 'Modelit',
                role: 'Mid-level Full-stack Developer',
                year: 'September 2021 - Current',
                first: 'Developed, tested, debugged, and documented software applications used by +500 partners',
                second: 'Used established coding standards and methodologies and coached 2 junior devs on best practices',
                third: 'Worked within an agile scrum team, contributing to 100% continuous improvement and deployment (CICD)',
                fourth: 'Designed and developed software applications based on user stories, requirements and design specifications',
                fifth: 'Documented software changes for use by engineers, quality assurance and documentation specialists',
                technologies: 'Vue, Laravel, Bootstrap, NodeJs, AWS, Salesforce, MariaDB',
            },
            whnz: {
                company: 'Career break',
                title: 'Working Holiday New Zealand',
                year: 'February 2020 - October 2021',
                first: 'Variery of jobs like dairy farmer, box maker, kiwi fruit picker, flower picker/packer, cleaner, kitchen hand, security officer, among others.',
            },
            universal: {
                company: 'Universal S.P.S.',
                role: 'IT Technician | HelpDesk Support',
                year: 'October 2017 - February 2020',
                first: 'Equipment and Servers maintenance and backups',
                second: 'Windows maintenance and configuration on new PCs',
                third: 'Helping users using different kind of software',
                fourth: 'PHP development and DB queries',
                fifth: 'Networking: networks, wiring, routers and cutting edge equipment maintenance',
            },
        },
        words: {
            title: 'title',
            role: 'role',
            company: 'company',
            school: 'school',
            year: 'year',
            activity: 'activity',
            country: 'country',
            task: 'task',
            tasks: 'tasks',
            technologies: 'technologies',
        },
    },
    services: {
        services: 'Services',
        webapp: {
            title: 'WebApp Development',
            description: 'Development of fully administrable web applications from any device and any place.',
        },
        design: {
            title: 'Web Design',
            description: 'Customized website design or based on templates to help grow your brand.',
        },
        hosting: {
            title: 'Hosting',
            description: 'Management and administration of the hosting where the website is located.',
        },
        consultancy: {
            title: 'Consulting Service',
            description: 'Help with questions about how to design a website or app to improve the efficiency and scope of your business.',
        },

    },
    contact: {
        contact: 'Contact Me',
        form: {
            name: 'Name *',
            email: 'Email *',
            subject: 'Subject *',
            message: 'Message *',
            button: 'Send Message',
            success: 'Message was sent successfully, I will be in contact with you soon.',
            error: 'Some error occurred while sending the message, please try again.',
        }
    },
    policy: {
        privacy_policy: 'Privacy Policy',
        last_updated: 'Last updated and effective: July 01, 2022.',
        policy: {
            one: {
                title: '1. Personal Information We May Collect',
                section_1: 'For the purpose of this Privacy Policy, “Personal Information” means any information relating to an identified or identifiable individual. We obtain Personal Information relating to you from various sources described below.',
                section_2: 'Where applicable, we indicate whether and why you must provide us with your Personal Information, as well as the consequences of failing to do so. If you do not provide Personal Information when requested, you may not be able to benefit from our Service if that information is necessary to provide you with the service or if we are legally required to collect it.',
            },
            two: {
                title: '2. Personal Information Provided by You',
                section_1: {
                    title: 'Customer Support',
                    text: 'We may collect information through your communications with our customer support team or other communications that you may send us and their contents.',
                },
                section_2: {
                    title: 'Log Data',
                    text: 'When you use our Service, our servers automatically record information that your browser sends whenever you visit a website (“Log Data”). This Log Data may include information such as your IP address, browser type or the domain from which you are visiting, the web-pages you visit, the search terms you use, and any advertisements on which you click.',
                },
                section_3: {
                    title: 'Cookies and Similar Technologies',
                    text_1: 'Like many websites, we also use “cookie” technology to collect additional website usage data and to improve the Site and our Service. A cookie is a small data file that we transfer to your computer’s hard disk. A session cookie enables certain features of the Site and our service and is deleted from your computer when you disconnect from or leave the Site. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Site. Persistent cookies can be removed by following your web browser help file directions. Most Internet browsers automatically accept cookies. Agustin Toth may use both session cookies and persistent cookies to better understand how you interact with the Site and our Service, to monitor aggregate usage by our users and web traffic routing on the Site, and to improve the Site and our Service.',
                    text_2: 'We may also automatically record certain information from your device by using various types of technology, including “clear gifs” or “web beacons.” This automatically collected information may include your IP address or other device address or ID, web browser and/or device type, the web pages or sites that you visit just before or just after you use the Service, the pages or other content you view or otherwise interact with on the Service, and the dates and times that you visit, access, or use the Service. We also may use these technologies to collect information regarding your interaction with email messages, such as whether you opened, clicked on, or forwarded a message, to the extent permitted under applicable law.',
                    text_3: 'You can instruct your browser, by editing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. Please note that if you delete, or choose not to accept, cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.',
                },
                section_4: {
                    title: 'Do Not Track',
                    text: 'Agustin Toth does not process or respond to web browsers “do not track” signals or other similar transmissions that indicate a request to disable online tracking of users who use our Service.',
                },
                section_5: {
                    title: 'Links to Other Websites',
                    text: 'Our Site contains links to other websites. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit Personal Information from you. Other sites follow different rules regarding the use or disclosure of the Personal Information you submit to them. We are not responsible for the content, privacy and security practices, and policies of third-party sites or services to which links or access are provided through the Service. We encourage you to read the privacy policies or statements of the other websites you visit.',
                },
            },
            three: {
                title: '3. How We May Use Your Personal Information',
                section_1: {
                    title: 'We may use the Personal Information we obtain about you to:',
                    point_1: '• Create and manage your account, provide our Service, process payments, and respond to your inquiries.',
                    point_2: '• Manage account authentication such as two-factor authentication.',
                    point_3: '• Communicate with you to verify your account and for informational and operational purposes, such as account management, customer service, or system maintenance, including by periodically emailing you service-related announcements.',
                    point_4: '• Tailor our Service (e.g., we may use cookies and similar technologies to remember your preferences).',
                    point_5: '• Publish your content, comments or messages on social media platforms.',
                    point_6: '• Provide tailored advertising, for Agustin Toth services, via Google AdWords.',
                    point_7: '• Aggregate your Personal Information for analytical purposes.',
                    point_8: '• Provide customer support.',
                    point_9: '• Operate, evaluate and improve our business (including by developing new products and services, managing our communications, determining the effectiveness of our advertising, analyzing how the Service is being accessed and used, tracking performance of the Service, debugging the Service, facilitating the use of our Service).',
                    point_10: '• Send you marketing communications about products, services, offers, programs and promotions of Agustin Toth, and affiliated companies.',
                    point_11: '• Ensure the security of our Service.',
                    point_12: '• Manage our customer, service provider and partner relationships.',
                    point_13: '• Enforce our agreements related to our Service and our other legal rights.',
                    point_14: '• Comply with applicable legal requirements, industry standards and our policies.',
                },
                section_2: {
                    title: 'If you are located in the European Economic Area, we may process your Personal Information for the above purposes when:',
                    point_1: '• You have consented to the use of your Personal Information, For example, we may seek to obtain your consent for our uses of cookies or similar technologies, or to send you marketing communications.',
                    point_2: '• We need your Personal Information to provide you with services and products requested by you, or to respond to your inquiries.',
                    point_3: '• We have a legal obligation to use your Personal Information.',
                    point_4: '• We have a legitimate interest in using your Personal Information. In particular, we have a legitimate interest in using your Personal Information to ensure and improve the safety, security, and performance of our Service, to anonymize Personal Information and carry out data analyses.',
                },
            },
            four: {
                title: '4. How We Share Your Personal Information',
                section_1: 'We may disclose the Personal Information we collect about you as described below or otherwise disclosed to you at the time the data is collected, including with:',
                section_2: {
                    title: 'Service Providers',
                    text: 'We engage certain trusted third parties to perform functions and provide services to us, including hosting and maintenance, error monitoring, debugging, performance monitoring, billing, customer relationship, database storage and management, and direct marketing campaigns. We may share your Personal Information with these third parties, but only to the extent necessary to perform these functions and provide such services. We also require these third parties to maintain the privacy and security of the Personal Information they process on our behalf.',
                },
                section_3: {
                    title: 'Compliance with Laws and Law Enforcement',
                    text: 'Agustin Toth cooperates with government and law enforcement officials or private parties to enforce and comply with the law. To the extent permitted under applicable law, we may disclose any information about you to government or law enforcement officials or private parties as we believe is necessary or appropriate to investigate, respond to, and defend against claims, for legal process (including subpoenas), to protect the property and rights of Agustin Toth or a third party, to protect Agustin Toth against liability, for the safety of the public or any person, to prevent or stop any illegal, unethical, fraudulent, abusive, or legally actionable activity, to protect the security or integrity of the Service and any equipment used to make the Service available, or to comply with the law.',
                },
                section_4: {
                    title: 'Other Third Parties',
                    text: 'We may share Personal Information with our headquarters and affiliates, and business partners to whom it is reasonably necessary or desirable for us to disclose your data for the purposes described in this Privacy Policy. We may also make certain non-Personal Information available to third parties for various purposes, including for business or marketing purposes or to assist third parties in understanding our users’ interest, habits, and usage patterns for certain programs, content, services, advertisements, promotions, and functionality available through the Service.',
                },
            },
            five: {
                title: '5. How We Protect Your Personal Information',
                section_1: 'Agustin Toth is very concerned about safeguarding the confidentiality of your Personal Information. We employ administrative and electronic measures designed to appropriately protect your Personal Information against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful form of processing of the Personal Information in our possession. Please be aware that no security measures are perfect or impenetrable. We cannot guarantee that information about you will not be accessed, viewed, disclosed, altered, or destroyed by breach of any of our administrative, physical, and electronic safeguards, subject to requirements under applicable law to ensure or warrant information security.',
                section_2: 'We will make any legally-required disclosures of any breach of the security, confidentiality, or integrity of your unencrypted electronically stored Personal Information to you via email or conspicuous posting on our Site in the most expedient time possible and without unreasonable delay, consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system , and any other disclosures that may be required under applicable law.',
                section_3: 'We also take measures to delete your Personal Information or keep it in a form that does not permit identifying you when this information is no longer necessary for the purposes for which we process it, unless we are required by law to keep this information for a longer period. When determining the retention period, we take into account various criteria, such as the type of products and services requested by or provided to you, the nature and length of our relationship with you, possible re-enrollment with our products or services, the impact on the services we provide to you if we delete some information from or about you, mandatory retention periods provided by law and the statute of limitations.',
            },
            six: {
                title: '6. Your Rights and Choices',
                section_1: 'If you decide at any time that you no longer wish to receive such marketing communications from us, please follow the unsubscribe instructions provided in any of the communications. You may also opt out from receiving commercial email from us by sending your request to us by email at support@agustintoth.xyz. Please be aware that, even after you opt out from receiving commercial messages from us, you will continue to receive administrative messages from us regarding the Service.',
                section_2: 'In certain jurisdictions you have the right to request access and receive information about the Personal Information we maintain about you, to update and correct inaccuracies in your Personal Information, to restrict or object to the processing of your Personal Information, to have the information blocked, anonymized or deleted, as appropriate, or to exercise your right to data portability to transfer your Personal Information to another company. Those rights may be limited in some circumstances by local law requirements. In addition to the above-mentioned rights, you also have the right to lodge a complaint with a competent supervisory authority subject to applicable law.',
                section_3: 'Where required by law, we obtain your consent for the processing of certain Personal Information collected by cookies or similar technologies, or used to send you direct marketing communications, or when we carry out other processing activities for which consent may be required. If we rely on consent for the processing of your Personal Information, you have the right to withdraw it at any time and free of charge. When you do so, this will not affect the lawfulness of the processing before your consent withdrawal.',
                section_4: 'To update your preferences, ask us to remove your information from our mailing lists, delete your account or submit a request to exercise your rights under applicable law, please contact us as specified in the “General contact” section.',
            },
            seven: {
                title: '7. Data Transfers',
                section_1: 'Agustin Toth is based in Uruguay. Personal Information that we collect may be transferred to, and stored at, any of our affiliates, partners or service providers which may be inside or outside the European Economic Area (“EEA”) and Switzerland, including the United States. By submitting your personal data, you agree to such transfers. Your Personal Information may be transferred to countries that do not have the same data protection laws as the country in which you initially provided the information. When we transfer or disclose your Personal Information to other countries, we will protect that information as described in this Privacy Policy.',
            },
            eigth: {
                title: '8. Children’s Privacy',
                section_1: 'The Site is not directed to persons under 16. If a parent or guardian becomes aware that his or her child has provided us with Personal Information without their consent, he or she should contact us at support@agustintoth.xyz. We do not knowingly collect Personal Information from children under 16. If we become aware that a child under 16 has provided us with Personal Information, we will delete such information from our files.',
            },
            nine: {
                title: '9. Updates to This Privacy Policy',
                section_1: 'This Privacy Policy may be updated from time to time for any reason, each version will apply to information collected while it was in place. We will notify you of any modifications to our Privacy Policy by posting the new Privacy Policy on our Site and indicating the date of the latest revision. You are advised to consult this Privacy Policy regularly for any changes.',
                section_2: 'In the event that the modifications materially alter your rights or obligations hereunder, we will make reasonable efforts to notify you of the change. For example, we may send a message to your email address or generate a pop-up or similar notification when you access the Service for the first time after such material changes are made. Your continued use of the Service after the revised Privacy Policy has become effective indicates that you have read, understood and agreed to the current version of this Privacy Policy.',
            },
            ten: {
                title: '10. How to Contact Us',
                section_1: 'Agustin Toth  is the entity responsible for the processing of your Personal Information. If you have any questions or comments regarding this Privacy Policy, or if you would like to exercise your rights to your Personal Information, you may contact us by email at support@agustintoth.xyz.',
                section_2: 'If you have any thoughts or questions about this Privacy Policy, please contact us by email at support@agustintoth.xyz.',
            },
        },
    },
    not_found: {
        not_found: 'Page Not Found',
        msg: "We couldn't find this page you're looking for.",
        btn: 'Go to home',
    },
    footer: {
        copy: 'All rights reserved',
        policy: 'Privacy Policy',
    },
}

export const spanish = {
    language: "es",
    menu: {
        home: 'inicio',
        about: 'sobre-mi',
        summary: 'resumen',
        services: 'servicios',
        contact: 'contacto',
        policy: 'politicas-de-privacidad',
        not_found: 'no-encontrado',
    },
    home: {
        first: 'Hola! Mi nombre es',
        second: 'Desarrollador Full-stack y Diseñador Web',
    },
    about: {
        about: 'Sobre Mi',
        firstLine: 'Tengo 25 años, soy uruguayo actualmente viviendo en Uruguay pero disfruto mucho viajando al rededor del mundo!',
        secondLine: 'Desde pequeño fui un aficionado por la tecnologia y la programacion.',
        thirdLine: 'Empece mis estudios oficialmente en IT a los 15 años aunque gran parte de mi conocimiento fue siendo autodidacta.',
        fourthLine: 'Aqui les muestro algunas de las tecnologias con las que he estado trabajando y aprendiendo ultimamente:',
        design: 'diseño'
    },
    summary: {
        summary: 'Resumen',
        education: {
            title: 'Education',
            first: {
                title: 'Master in Full-stack Development',
                institute: 'Universitat Politècnica de Catalunya',
                year: '2021-2022',
                country: 'Spain',
            },
            second: {
                title: 'Analista Programador',
                institute: 'Universidad ORT',
                year: '2019',
                country: 'Uruguay',
            },
            third: {
                title: 'Bachillerato tecnológico | Técnico IT',
                institute: 'UTU ISBO',
                year: '2014-2017',
                country: 'Uruguay',
            },
        },
        certifications: {
            title: 'Certificaciones',
            first: {
                title: 'JavaScript Developer',
                institute: 'Salesforce',
                year: '2023',
                country: '-',
            },
            second: {
                title: 'Diseño Multimedia',
                institute: 'Universidad de Salamanca',
                year: '2019',
                country: 'España',
            },
            third: {
                title: 'Desarrollador PHP & MySQL',
                institute: 'Taller de Informática',
                year: '2018',
                country: 'Uruguay',
            },
            fourth: {
                title: 'Diseño Gráfico',
                institute: 'BIOS',
                year: '2017',
                country: 'Uruguay',
            },
            fifth: {
                title: 'Líder | Animador',
                institute: 'Colegio y Liceo Pedro Poveda',
                year: '2013-2014',
                country: 'Uruguay',
            },
        },
        work: {
            title: 'Work',
            modelit: {
                company: 'Modelit',
                role: 'Ssr. Full-stack Developer',
                year: 'Septiembre 2021 - Actualidad',
                first: 'Desarrollo, testing, debug y documentacion de aplicaciones web utilizadas por +500 partners',
                second: 'Utilicé estándares y metodologías de codificación establecidos y ayudé a entrenar 2 Jr devs para mejorar las prácticas',
                third: 'Trabajé dentro de un equipo utilizando SCRUM. contribuyendo al 100% de la mejora continua y deploys (CICD)',
                fourth: 'Diseño y desarrollo de aplicaciones de software basadas en tickets de usuarios, requisitos y especificaciones de diseño.',
                fifth: 'Documentación de cambios de software para uso de otros devs, QA entre otros profesionales.',
                technologies: 'Vue, Laravel, Bootstrap, NodeJs, AWS, Salesforce, MariaDB',
            },
            whnz: {
                company: 'Career break',
                title: 'Working Holiday New Zealand',
                year: 'Febrero 2020 - Octubre 2021',
                first: 'Variedad de trabajos, en granja lechera, armando cajas, cosecha de kiwis, cosecha y empaquetado de flores, limpiador, ayudante de cocina, seguridad privada entre otros.',
            },
            universal: {
                company: 'Universal S.P.S.',
                role: 'Técnico IT | Soporte Técnico',
                year: 'Octubre 2017 - Febrero 2020',
                first: 'Mantenimiento y respaldo de equipos y servidores',
                second: 'Configuración y mantenimiento de Windows en nuevas PCs',
                third: 'Ayuda a usuarios utilizando diferentes tpos de software',
                fourth: 'Desarrollo PHP y consultas en Postgres DB',
                fifth: 'Mantenimiento de redes, cableados, routers y switches',
            },
        },
        words: {
            title: 'título',
            role: 'rol',
            company: 'empresa',
            school: 'instituto',
            year: 'año',
            activity: 'actividad',
            country: 'país',
            task: 'tarea',
            tasks: 'tareas',
            technologies: 'tecnologías',
        },
    },
    services: {
        services: 'Servicios',
        webapp: {
            title: 'Desarrollo de Apps',
            description: 'Desarrollo de una aplicación web totalmente administrable desde cualquier dispoitivo y cualquier lugar.',
        },
        design: {
            title: 'Diseño Web',
            description: 'Diseño web completamente personalizado o hecho a base de plantillas para hacer crecer tu marca.',
        },
        hosting: {
            title: 'Hosting',
            description: 'Manejo y administracion del hosting donde estará ubicado el sitio web.',
        },
        consultancy: {
            title: 'Consultoria',
            description: 'Ayuda ante consultas sobre como diseñar un website o app para mejorar la eficiencia y alcance de tu negocio.',
        },

    },
    contact: {
        contact: 'Contact Me',
        form: {
            name: 'Nombre *',
            email: 'Email *',
            subject: 'Asunto *',
            message: 'Mensaje *',
            button: 'Enviar Mensaje',
            success: 'Mensaje enviado con éxito, me contactaré a la brevedad.',
            error: 'Ocurrió un error, por favor intenta nuevamente.',
        }
    },
    policy: {
        privacy_policy: 'Políticas de Privacidad',
        last_updated: 'Última actualización efectiva: 01 de Julio de 2022.',
        policy: {
            one: {
                title: '1. Información personal que podemos recopilar',
                section_1: 'A los efectos de esta Política de privacidad, "Información personal" significa cualquier información relacionada con una persona identificada o identificable. Obtenemos información personal relacionada con usted de varias fuentes que se describen a continuación.',
                section_2: 'En su caso, indicamos si y por qué debe proporcionarnos su información personal, así como las consecuencias de no hacerlo. Si no proporciona Información personal cuando se le solicita, es posible que no pueda beneficiarse de nuestro Servicio si esa información es necesaria para brindarle el servicio o si estamos legalmente obligados a recopilarla.',
            },
            two: {
                title: '2. Información personal proporcionada por usted',
                section_1: {
                    title: 'Atención al cliente',
                    text: 'Podemos recopilar información a través de sus comunicaciones con nuestro equipo de atención al cliente u otras comunicaciones que pueda enviarnos y sus contenidos.',
                },
                section_2: {
                    title: 'Dato de registro',
                    text: 'Cuando utiliza nuestro Servicio, nuestros servidores registran automáticamente la información que su navegador envía cada vez que visita un sitio web ("Datos de registro"). Estos datos de registro pueden incluir información como su dirección IP, el tipo de navegador o el dominio desde el que está visitando, las páginas web que visita, los términos de búsqueda que utiliza y los anuncios en los que hace clic.',
                },
                section_3: {
                    title: 'Cookies y tecnologías similares',
                    text_1: 'Al igual que muchos sitios web, también utilizamos la tecnología de "cookies" para recopilar datos adicionales de uso del sitio web y para mejorar el Sitio y nuestro Servicio. Una cookie es un pequeño archivo de datos que transferimos al disco duro de su computadora. Una cookie de sesión habilita ciertas características del Sitio y nuestro servicio y se elimina de su computadora cuando se desconecta o abandona el Sitio. Una cookie persistente permanece después de que cierra su navegador y puede ser utilizada por su navegador en visitas posteriores al Sitio. Las cookies persistentes se pueden eliminar siguiendo las direcciones del archivo de ayuda de tu navegador web. La mayoría de los navegadores de Internet aceptan automáticamente las cookies. Agustín Toth puede usar cookies de sesión y cookies persistentes para comprender mejor cómo interactúa con el Sitio y nuestro Servicio, para monitorear el uso agregado por parte de nuestros usuarios y el enrutamiento del tráfico web en el Sitio, y para mejorar el Sitio y nuestro Servicio.',
                    text_2: 'También podemos registrar automáticamente cierta información de su dispositivo mediante el uso de varios tipos de tecnología, incluidos "gif transparentes" o "balizas web". Esta información recopilada automáticamente puede incluir su dirección IP u otra dirección o identificación del dispositivo, navegador web y/o tipo de dispositivo, las páginas web o los sitios que visita justo antes o después de usar el Servicio, las páginas u otro contenido que ve o interactuar de otro modo con el Servicio, y las fechas y horas en que visita, accede o utiliza el Servicio. También podemos utilizar estas tecnologías para recopilar información sobre su interacción con los mensajes de correo electrónico, como si abrió, hizo clic o reenvió un mensaje, en la medida permitida por la ley aplicable.',
                    text_3: 'Puede indicarle a su navegador, editando sus opciones, que deje de aceptar cookies o que le pregunte antes de aceptar una cookie de los sitios web que visita. Tenga en cuenta que si elimina o elige no aceptar las cookies del Servicio, es posible que no pueda utilizar las funciones del Servicio en todo su potencial.',
                },
                section_4: {
                    title: 'No rastreo',
                    text: 'Agustin Toth no procesa ni responde a las señales de "no rastrear" de los navegadores web u otras transmisiones similares que indiquen una solicitud para desactivar el seguimiento en línea de los usuarios que utilizan nuestro Servicio.',
                },
                section_5: {
                    title: 'Enlaces a otros sitios web',
                    text: 'Nuestro sitio contiene enlaces a otros sitios web. El hecho de que enlacemos a un sitio web no es un respaldo, autorización o representación de nuestra afiliación con ese tercero. No ejercemos control sobre los sitios web de terceros. Estos otros sitios web pueden colocar sus propias cookies u otros archivos en su computadora, recopilar datos o solicitarle información personal. Otros sitios siguen reglas diferentes con respecto al uso o divulgación de la información personal que les envía. No somos responsables del contenido, las prácticas de privacidad y seguridad y las políticas de los sitios o servicios de terceros a los que se proporcionan enlaces o acceso a través del Servicio. Le recomendamos que lea las políticas o declaraciones de privacidad de los otros sitios web que visite.',
                },
            },
            three: {
                title: '3. Cómo podemos usar su información personal',
                section_1: {
                    title: 'Podemos utilizar la información personal que obtenemos sobre usted para:',
                    point_1: '• Crear y administrar su cuenta, brindar nuestro servicio, procesar pagos y responder a sus consultas.',
                    point_2: '• Administrar la autenticación de la cuenta, como la autenticación de dos factores.',
                    point_3: '• Comunicarnos con usted para verificar su cuenta y con fines informativos y operativos, como la administración de la cuenta, el servicio de atención al cliente o el mantenimiento del sistema, incluido el envío periódico de correos electrónicos con anuncios relacionados con el servicio.',
                    point_4: '• Adaptar nuestro servicio (p. ej., podemos usar cookies y tecnologías similares para recordar sus preferencias).',
                    point_5: '• Publicar su contenido, comentarios o mensajes en las plataformas de redes sociales.',
                    point_6: '• Proporcionar publicidad personalizada, para los servicios de Agustín Toth, a través de Google AdWords.',
                    point_7: '• Agregar su información personal para fines analíticos.',
                    point_8: '• Proveer atención al cliente',
                    point_9: '• Operar, evaluar y mejorar nuestro negocio (incluyendo el desarrollo de nuevos productos y servicios, la gestión de nuestras comunicaciones, la determinación de la eficacia de nuestra publicidad, el análisis de cómo se accede y utiliza el Servicio, el seguimiento del rendimiento del Servicio, la depuración del Servicio, la facilitación del uso de nuestro Servicio).',
                    point_10: '• Enviarle comunicaciones de marketing sobre productos, servicios, ofertas, programas y promociones de Agustin Toth, y empresas afiliadas.',
                    point_11: '• Garantizar la seguridad de nuestro servicio.',
                    point_12: '• Gestionar nuestras relaciones con clientes, proveedores de servicios y socios.',
                    point_13: '• Hacer cumplir nuestros acuerdos relacionados con nuestro servicio y nuestros otros derechos legales.',
                    point_14: '• Cumplir con los requisitos legales aplicables, los estándares de la industria y nuestras políticas.',
                },
                section_2: {
                    title: 'Si se encuentra en el Espacio Económico Europeo, podemos procesar su información personal para los fines anteriores cuando:',
                    point_1: '• Ha dado su consentimiento para el uso de su información personal. Por ejemplo, podemos buscar obtener su consentimiento para nuestros usos de cookies o tecnologías similares, o para enviarle comunicaciones de marketing.',
                    point_2: '• Necesitamos su información personal para brindarle los servicios y productos solicitados por usted, o para responder a sus consultas.',
                    point_3: '• Tenemos la obligación legal de utilizar su información personal.',
                    point_4: '• Tenemos un interés legítimo en utilizar su información personal. En particular, tenemos un interés legítimo en utilizar su Información personal para garantizar y mejorar la seguridad y el rendimiento de nuestro Servicio, para anonimizar la Información personal y realizar análisis de datos.',
                },
            },
            four: {
                title: '4. Cómo compartimos su información personal',
                section_1: 'Podemos divulgar la información personal que recopilamos sobre usted como se describe a continuación o divulgarla de otra manera en el momento en que se recopilan los datos, incluso con:',
                section_2: {
                    title: 'Proveedores de servicio',
                    text: 'Contratamos a ciertos terceros de confianza para que realicen funciones y nos brinden servicios, incluido el alojamiento y el mantenimiento, el control de errores, la depuración, el control del rendimiento, la facturación, la relación con el cliente, el almacenamiento y la gestión de bases de datos y las campañas de marketing directo. Podemos compartir su información personal con estos terceros, pero solo en la medida necesaria para realizar estas funciones y proporcionar dichos servicios. También exigimos a estos terceros que mantengan la privacidad y seguridad de la Información personal que procesan en nuestro nombre.',
                },
                section_3: {
                    title: 'Cumplimiento de las leyes y aplicación de la ley',
                    text: 'Agustin Toth coopera con el gobierno y los funcionarios encargados de hacer cumplir la ley o con entidades privadas para hacer cumplir y cumplir la ley. En la medida en que lo permita la ley aplicable, podemos divulgar cualquier información sobre usted a funcionarios gubernamentales o encargados de hacer cumplir la ley o a partes privadas, según lo consideremos necesario o apropiado para investigar, responder y defenderse de reclamos, para procesos legales (incluidas citaciones), para proteger la propiedad y los derechos de Agustin Toth o de un tercero, para proteger a Agustin Toth contra responsabilidad, para la seguridad del público o de cualquier persona, para prevenir o detener cualquier actividad ilegal, no ética, fraudulenta, abusiva o legalmente procesable, para proteger la seguridad o integridad del Servicio y cualquier equipo utilizado para hacer que el Servicio esté disponible o para cumplir con la ley.',
                },
                section_4: {
                    title: 'Otros terceros',
                    text: 'Podemos compartir información personal con nuestras oficinas centrales y afiliadas, y socios comerciales a quienes sea razonablemente necesario o deseable que divulguemos sus datos para los fines descritos en esta Política de privacidad. También podemos poner cierta Información no personal a disposición de terceros para diversos fines, incluidos fines comerciales o de marketing, o para ayudar a terceros a comprender los intereses, hábitos y patrones de uso de nuestros usuarios para ciertos programas, contenido, servicios, anuncios, promociones y funcionalidades disponibles a través del Servicio.',
                },
            },
            five: {
                title: '5. Cómo protegemos su información personal',
                section_1: 'Agustín Toth se preocupa mucho por salvaguardar la confidencialidad de su Información Personal. Empleamos medidas administrativas y electrónicas diseñadas para proteger adecuadamente su Información personal contra destrucción accidental o ilegal, pérdida accidental, alteración no autorizada, divulgación o acceso no autorizado, uso indebido y cualquier otra forma ilegal de procesamiento de la Información personal en nuestro poder. Tenga en cuenta que ninguna medida de seguridad es perfecta o impenetrable. No podemos garantizar que no se acceda, visualice, divulgue, altere o destruya su información por incumplimiento de cualquiera de nuestras medidas de seguridad administrativas, físicas y electrónicas, sujeto a los requisitos de la ley aplicable para garantizar o garantizar la seguridad de la información.',
                section_2: 'Haremos las divulgaciones legalmente requeridas de cualquier violación de la seguridad, confidencialidad o integridad de su Información personal almacenada electrónicamente sin cifrar a usted por correo electrónico o publicación visible en nuestro Sitio en el tiempo más conveniente posible y sin demora injustificada, de conformidad con ( i) las necesidades legítimas de la aplicación de la ley o (ii) cualquier medida necesaria para determinar el alcance de la infracción y restaurar la integridad razonable del sistema de datos, y cualquier otra divulgación que pueda ser requerida por la ley aplicable.',
                section_3: 'También tomamos medidas para eliminar su información personal o mantenerla en un formato que no permita identificarlo cuando esta información ya no sea necesaria para los fines para los que la procesamos, a menos que la ley nos exija que conservemos esta información por más tiempo. período. Al determinar el período de retención, tenemos en cuenta varios criterios, como el tipo de productos y servicios solicitados o proporcionados por usted, la naturaleza y la duración de nuestra relación con usted, la posible reinscripción en nuestros productos o servicios, el impacto sobre los servicios que le brindamos si eliminamos alguna información de usted o sobre usted, los períodos de retención obligatorios proporcionados por la ley y el estatuto de limitaciones.',
            },
            six: {
                title: '6. Sus derechos y opciones',
                section_1: 'Si decide en cualquier momento que ya no desea recibir dichas comunicaciones de marketing de parte nuestra, siga las instrucciones para darse de baja proporcionadas en cualquiera de las comunicaciones. También puede optar por no recibir nuestros correos electrónicos comerciales enviándonos su solicitud por correo electrónico a support@agustintoth.xyz. Tenga en cuenta que, incluso después de optar por no recibir nuestros mensajes comerciales, seguirá recibiendo mensajes administrativos de nuestra parte con respecto al Servicio.',
                section_2: 'En ciertas jurisdicciones, tiene derecho a solicitar acceso y recibir información sobre la Información personal que mantenemos sobre usted, actualizar y corregir imprecisiones en su Información personal, restringir u oponerse al procesamiento de su Información personal, bloquear la información, anonimizados o eliminados, según corresponda, o para ejercer su derecho a la portabilidad de datos para transferir su Información personal a otra empresa. Esos derechos pueden estar limitados en algunas circunstancias por los requisitos de la ley local. Además de los derechos mencionados anteriormente, también tiene derecho a presentar una queja ante una autoridad de control competente sujeto a la ley aplicable.',
                section_3: 'Cuando lo exija la ley, obtenemos su consentimiento para el procesamiento de cierta Información personal recopilada por cookies o tecnologías similares, o utilizada para enviarle comunicaciones de marketing directo, o cuando llevamos a cabo otras actividades de procesamiento para las cuales se puede requerir el consentimiento. Si nos basamos en el consentimiento para el procesamiento de su información personal, tiene derecho a retirarlo en cualquier momento y de forma gratuita. Cuando lo haga, esto no afectará la legalidad del procesamiento antes de retirar su consentimiento.',
                section_4: 'Para actualizar sus preferencias, solicitarnos que eliminemos su información de nuestras listas de correo, eliminar su cuenta o enviar una solicitud para ejercer sus derechos según la ley aplicable, comuníquese con nosotros como se especifica en la sección "Contacto general".',
            },
            seven: {
                title: '7. Transferencias de datos',
                section_1: 'Agustín Toth tiene su sede en Uruguay. La información personal que recopilamos puede transferirse y almacenarse en cualquiera de nuestros afiliados, socios o proveedores de servicios que pueden estar dentro o fuera del Espacio Económico Europeo ("EEE") y Suiza, incluidos los Estados Unidos. Al enviar sus datos personales, acepta dichas transferencias. Su información personal puede transferirse a países que no tienen las mismas leyes de protección de datos que el país en el que proporcionó inicialmente la información. Cuando transfiramos o divulguemos su información personal a otros países, protegeremos esa información como se describe en esta Política de privacidad.',
            },
            eigth: {
                title: '8. Privacidad de los niños',
                section_1: 'El Sitio no está dirigido a personas menores de 16 años. Si un padre o tutor se entera de que su hijo nos ha proporcionado Información personal sin su consentimiento, debe comunicarse con nosotros a support@agustintoth.xyz. No recopilamos a sabiendas información personal de niños menores de 16 años. Si nos damos cuenta de que un niño menor de 16 años nos ha proporcionado información personal, eliminaremos dicha información de nuestros archivos.',
            },
            nine: {
                title: '9. Actualizaciones a esta política de privacidad',
                section_1: 'Esta Política de privacidad puede actualizarse de vez en cuando por cualquier motivo, cada versión se aplicará a la información recopilada mientras estuvo vigente. Le notificaremos de cualquier modificación a nuestra Política de Privacidad publicando la nueva Política de Privacidad en nuestro Sitio e indicando la fecha de la última revisión. Se le recomienda consultar esta Política de privacidad regularmente para cualquier cambio.',
                section_2: 'En el caso de que las modificaciones alteren materialmente sus derechos u obligaciones en virtud del presente, haremos todos los esfuerzos razonables para notificarle el cambio. Por ejemplo, podemos enviar un mensaje a su dirección de correo electrónico o generar una ventana emergente o una notificación similar cuando acceda al Servicio por primera vez después de realizar dichos cambios importantes. Su uso continuado del Servicio después de que la Política de privacidad revisada haya entrado en vigencia indica que ha leído, entendido y aceptado la versión actual de esta Política de privacidad.',
            },
            ten: {
                title: '10. Como ponerse en contacto',
                section_1: 'Agustin Toth es la entidad responsable del tratamiento de sus Datos Personales. Si tiene alguna pregunta o comentario con respecto a esta Política de privacidad, o si desea ejercer sus derechos sobre su Información personal, puede comunicarse con nosotros por correo electrónico a support@agustintoth.xyz.',
                section_2: 'Si tiene alguna idea o pregunta sobre esta Política de privacidad, contáctenos por correo electrónico a support@agustintoth.xyz.',
            },
        },
    },
    not_found: {
        not_found: 'Página no encontrada',
        msg: "No pudimos encontrar la página que estas buscando.",
        btn: 'Ir al inicio',
    },
    footer: {
        copy: 'Todos los derechos reservados',
        policy: 'Políticas de Privacidad',
    },
}